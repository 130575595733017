<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import {
	projectMethods,
  userMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "New Project",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  data() {
    return {
      title: "",
      items: [],
      showModalHost: false,
      showModalProducts:false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      project:null,
      submitted:false,
      projects:[],
      integrationModal:false,
      apikey:'****************',
    }
  },
  validations: {
    project: {
      name: {
        required,
      }
    },
  },
  mounted() {
    this.loadProjects(this.$route.query.id);
    
  },
  created() {
    
  },
  methods: {
    ...projectMethods,
    ...userMethods,

    onProjectSelected(){
      if(this.project && this.project._id){
        this.loadProject(this.project._id)
      }
    },
    loadProjects(id){
      let loader = this.$loading.show();
      this.getProjects().then((projects)=>{
        if(projects&& projects.data){
          this.projects= projects.data
          if(id){
            this.project = this.projects.filter(p=>p._id== id).length > 0 ? this.projects.filter(p=>p._id== id)[0] : {};
            this.onProjectSelected();
          }
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('projects.get_projects_error') ,title: this.$t('projects.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    loadProject(id){
      let loader = this.$loading.show();

      this.getProject(id).then((res)=>{
        this.project = res.data;
      }).catch(()=>{

      }).finally(()=>{
        loader.hide()
      })
    },

    onIntegrationClicked(){
      this.integrationModal = true;
    },

    onIntegrationDocClicked(){
      window.open('https://docs.goshops.com/api-reference/recommendations')
    },

    onMoreInfoClicked(type){
      if(type=='userReco'){
        window.open('https://docs.goshops.com/admin-ui/go-recommendation/algoritmos-disponibles#recomendaciones-para-usuario')
      }else if(type=="fbt"){
        window.open('https://docs.goshops.com/admin-ui/go-recommendation/algoritmos-disponibles#frecuentemente-comprados-juntos')
      }else if(type=="similar"){
        window.open('https://docs.goshops.com/admin-ui/go-recommendation/algoritmos-disponibles#productos-similares')
      }else if(type=="trending"){
        window.open('https://docs.goshops.com/admin-ui/go-recommendation/algoritmos-disponibles#trendings')
      }
    },

    copyValue(value){
            if(value=='project'){
                this.$notify({ type: 'success', text: this.$t('items.add_project_copied'),title:  this.$t('items.title') });
                navigator.clipboard.writeText(this.project._id);
            }
            else{

                let loader = this.$loading.show();

                const currentUser = JSON.parse(localStorage.getItem("auth.currentUser"));

                this.getUser(currentUser.user._id).then((user)=>{
                    this.$notify({ type: 'success', text: this.$t('items.add_apikey_copied'),title:  this.$t('items.title') });
                    navigator.clipboard.writeText(user.data.apiKey);
                }).catch(()=>{
                    this.$notify({ type: 'error', text: this.$t('items.add_apikey_error'),title:  this.$t('items.title') });
                }).finally(()=>{
                    loader.hide();
                })
                    
                
            }
                
        },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">  
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-sm-4">
                  <div style="width:100%">
                      <label for="role">{{$t('projects.title')}}</label>
                      <multiselect 
                        v-model="project"
                        :options="projects"
                        :selectLabel="$t('common.select')"
                        :deselectLabel="$t('common.deselect')"
                        :placeholder="$t('projects.select_placeholder')"
                        :multiple="false"
                        track-by="_id" 
                        label="name"
                        :showNoResults="false"
                        @input="onProjectSelected">
                      </multiselect>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="project!=null">
      <div class="col-xl-3 col-sm-6">
          <div class="card interact">
            <div class="card-body">
              <div class="d-flex">
                  <div class="flex-grow-1 overflow-hidden">
                      <h5 class="text-truncate font-size-15">
                        <i class="mdi mdi-account-heart"></i> <a class="text-dark">Para usuario</a>
                      </h5>
                      <p class="text-muted campaign-description">Muestra los productos que realmente le pueden interesar a un cliente basado en su actividad y gustos aumentando la tasa de conversión. Generalmente utilizado en la página de inicio.</p>
                    </div>
                  </div>
                  <div class="text-center">
                    <!--<switches v-model="useUserRecomendations" type-bold="false" color="primary" class="ml-1 mb-0 mt-2" emit-on-mount="false"></switches>-->
                  </div>
                  <div v-on:click="onMoreInfoClicked('userReco')"> <i class= 'bx bx-info-circle interact'></i> Más info </div>
                  <div v-on:click="onIntegrationClicked"> <i class= 'bx bx-code interact'></i> Integrar</div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6">
            <div class="card interact">
              <div class="card-body">
                <div class="d-flex">
                    <div class="flex-grow-1 overflow-hidden">
                        <h5 class="text-truncate font-size-15">
                          <i class="mdi mdi-basket-plus"></i> <a class="text-dark">Comprados juntos</a>
                        </h5>
                        <p class="text-muted campaign-description">Permite realizar cross-selling mostrando productos que complementan la selección. Generalmente utilizado en páginas de checkout o detalle de producto.</p>
                      </div>
                    </div>
                    <div class="text-center">
                      <!--<switches v-model="useFbt" type-bold="false" color="primary" class="ml-1 mb-0 mt-2"></switches>-->
                    </div>
                    <div v-on:click="onMoreInfoClicked('fbt')"><i class= 'bx bx-info-circle interact'></i> Más info </div>
                    <div v-on:click="onIntegrationClicked"><i class= 'bx bx-code interact'></i> Integrar </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6">
          <div class="card interact">
            <div class="card-body">
              <div class="d-flex">
                  <div class="flex-grow-1 overflow-hidden">
                      <h5 class="text-truncate font-size-15">
                        <i class="mdi mdi-sync"></i> <a class="text-dark">Productos similares</a>
                      </h5>
                      <p class="text-muted campaign-description">Maximiza conversiones y el descubrimiento de tus productos mostrando otras opciones al producto seleccionado. Generalmente utilizado en página de detalle de producto. </p>
                    </div>
                  </div>
                  <div class="text-center">
                    <!--<switches v-model="useSimilarProducts" type-bold="false" color="primary" class="ml-1 mb-0 mt-2"></switches>-->
                  </div>
                  <div v-on:click="onMoreInfoClicked('similar')"><i class= 'bx bx-info-circle interact'></i> Más info </div>
                  <div v-on:click="onIntegrationClicked"><i class= 'bx bx-code interact'></i> Integrar </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6">
          <div class="card interact">
            <div class="card-body">
              <div class="d-flex">
                  <div class="flex-grow-1 overflow-hidden">
                      <h5 class="text-truncate font-size-15">
                        <i class="mdi mdi-trending-up"></i> <a class="text-dark">Trendings</a>
                      </h5>
                      <p class="text-muted campaign-description">Aumenta el engage de tus clientes mostrando productos que son trendings. Generalmente utilizado en página de inicio y landings. </p>
                    </div>
                  </div>
                  <div class="text-center">
                    <!--<switches v-model="useTrendings" type-bold="false" color="primary" class="ml-1 mb-0 mt-2"></switches>-->
                  </div>
                  <div v-on:click="onMoreInfoClicked('trending')"><i class= 'bx bx-info-circle interact'></i> Más info </div>
                  <div v-on:click="onIntegrationClicked"><i class= 'bx bx-code interact'></i> Integrar </div>
              </div>
            </div>
          </div>
    </div>
    <b-modal v-model="integrationModal" id="modal-center" hide-footer title="Integrar" title-class="font-18">
      <div class="row mt-2" >
            <p class="text-muted"> Sigue la documentación utilizando estos datos para obtener los productos recomendados mediante este algoritmo
              <br>
              <a v-on:click="onIntegrationDocClicked()" class="mt-0 mt-2 interact"> <i class= 'bx bx-code'></i> API Reference</a>
            </p>

            <div class="row">
                <div class="col">
                    <div class="mb-3">
                        <label>x-api-key:</label>
                        <div class="d-flex">
                            <input
                                v-model="apikey"
                                type="password"
                                class="form-control"
                                disabled
                            />
                            <i :class="`font-size-17 bx bx-copy me-3 ml-2 interact`" v-on:click="copyValue('apikey')" style="align-self: center; margin-left: 5px;"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" >
                <div class="col">
                    <div class="mb-3">
                        <label>{{$t('items.add_project')}}:</label>
                        <div class="d-flex" v-if="project">
                            <input
                            id="projectid"
                            v-model="project._id"
                            type="text"
                            class="form-control"
                            disabled
                            />
                            <i :class="`font-size-17 bx bx-copy me-3 ml-2 interact`" v-on:click="copyValue('project')" style="align-self: center; margin-left: 5px;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
  </Layout>
</template>

<style scoped>

.edit-picture-button {
    height: 1.5rem !important;
    width: 1.5rem !important;
    top: -5px;
    position: absolute;
    color:#adb5bd
}

.icon-color-grey {
  color:#adb5bd
}

.interact{
	cursor: pointer;
  }
</style>